<template>
    <div class="residentdatabase-fixed-business">
        <div class="head-controller-box">
            <div class="back-btn" @click="Route.back()">
                <div class="icon liefeng-icon liefeng-icon-direction-left"></div>
            </div>
            <div class="title-box">自定义固定业务功能</div>
            <button class="cancel-button" @click="Route.back()">
                <div class="icon liefeng-icon liefeng-icon-close"></div>
                <p class="submit">取消</p>
            </button>
        </div>
        <div class="content-box" v-if="detail">
            <div class="options-box">
                <div class="item-box step-item" style="padding-bottom: 20px;">
                    <div class="item-base step-base">
                        <div class="title-box">
                            <p class="serial-number">1</p>
                            <p class="name">表单属性</p>
                        </div>
                        <div class="step-content-box">
                            <div class="form-box">
                                <div class="form-item">
                                    <div class="name-box">业务功能名称</div>
                                    <Input style="width:300px" v-model="businessName" maxlength="50" placeholder="请填写业务功能名称"></Input>
                                </div>
                                <div class="form-item">
                                    <div class="name-box">业务功能描述</div>
                                    <Input style="width:300px" type="textarea" v-model="businessDescription" maxlength="150" placeholder="请填写业务功能描述"></Input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item-box step-item" v-for="(conf, idx) in detail.formProperties" :key="idx">
                    <div class="item-base step-base" :class="'step-item-' + idx">
                        <div class="title-box">
                            <p class="serial-number">{{ idx + 2 }}</p>
                            <p class="name">{{ conf.formName }}</p>
                        </div>
                        <div class="step-content-box">
                            <div class="checkbox" v-for="(item, index) in conf.formOperates" :key="index" v-show="item.fieldType !== 'hidden'">
                                <Checkbox label="twitter" :value="selected.findIndex(v => v.fieldId === item.fieldId) > -1" :disabled="item.fixed" @on-change="onChangeCheckbox(item, $event, true)">
                                    <span>{{ item.fieldName }}</span>
                                    <Poptip v-if="item.selectContent" width="200" :word-wrap="true" trigger="hover" :content="item.selectContent">
                                        <Icon size="18" type="ios-information-circle" />
                                    </Poptip>
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="selected-box">
                <div class="title-box">已选组件</div>
                <div class="selected-base">
                    <div class="item-box" v-for="(item, idx) in selected" :key="idx">
                        <p class="name">
                            {{ idx + 1 }}. {{ item.fieldName }}
                            <Poptip v-if="item.selectContent" width="200" :word-wrap="true" trigger="hover" :content="item.selectContent">
                                <Icon size="18" type="ios-information-circle" />
                            </Poptip>
                        </p>
                        <div class="delete-box liefeng-icon liefeng-icon-close" v-if="!item.fixed" @click="onDeleteSelected(idx)"></div>
                        <div class="check-box" v-if="!item.fixed">
                            <Checkbox v-model="item.required">必填</Checkbox>
                        </div>
                    </div>
                    <div class="empty" v-if="selected.length <= 0">请勾选左侧字段名</div>
                </div>
                <div class="preview" @click="onPreview">预览效果</div>
            </div>
        </div>

        <PreviewTable ref="preview_table" @on-submit="onSubmit"></PreviewTable>
    </div>
</template>

<script>
import PreviewTable from "./preview/table.vue"
import Utils from "../jointly/utils/utils"
import Route from "../communitymobilization/entity/Route"

export default {
    components: { PreviewTable },

    data() {
        return {
            Route: Route,
            detail: null,
            selected: [],

            businessName: "",
            businessDescription: "",
        }
    },

    async created() {
        const id = this.$core.getUrlParam("id")
        // 获取基础数据
        await this.getBasicForm()
        id && this.getTabChildsData(id)
    },

    methods: {
        getBasicForm() {
            return this.$get("/gateway/api/fastDev/AppForm/findFormByAppCode", {
                appCode: "UserInfo",
            }).then(res => {
                if (res.code == "200") {
                    const data = res.data
                    const fps = data.formProperties

                    for (let i = 0; i < fps.length; i++) {
                        for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                            let v = fos[fi]

                            if (v.options) {
                                v.selectContent = this.getSelectContent(v.options)
                            }

                            if (v.fixed) {
                                // 触发变化事件
                                this.onChangeCheckbox(v, true)
                            }
                        }
                    }

                    this.detail = data
                }
            })
        },

        /**
         * 获取选择字段预览值
         */
        getSelectContent(opt) {
            var res = []

            for (let i = 0; i < opt.length; i++) {
                let v = opt[i]

                res.push(`${i + 1}.${v.label}`)
            }

            return res.join("；")
        },

        getTabChildsData(id) {
            this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: id,
            }).then(res => {
                if (res.code == "200") {
                    const data = res.data

                    this.businessId = data.appId
                    this.businessName = data.appName
                    this.businessDescription = data.description

                    for (let i = 0, fps = res.data.formProperties; i < fps.length; i++) {
                        for (let oi = 0, o = fps[i].formOperates; oi < o.length; oi++) {
                            let v = o[oi]

                            if (v.fieldType === "hidden") {
                                continue
                            }

                            // 触发勾选事件
                            this.onChangeCheckbox(v, true)
                        }
                    }
                }
            })
        },

        onChangeCheckbox(item, value, required) {
            if (value) {
                for (let i = 0, l = this.selected; i < l.length; i++) {
                    if (l[i].fieldId === item.fieldId) return
                }
                // 勾选
                !item.selected && (item.selected = true)
                // 默认为必填项
                if (required) {
                    item.required = true
                }
                // 添加到已选数组里
                this.selected.push(item)
            } else {
                for (let i = 0, l = this.selected; i < l.length; i++) {
                    const v = l[i]

                    if (v.fieldId === item.fieldId) {
                        this.selected.splice(i, 1)
                        break
                    }
                }
            }
        },

        onDeleteSelected(idx) {
            // 取消勾选
            this.selected[idx].selected = false
            // 移除已选列表
            this.selected.splice(idx, 1)
        },

        checkString(target) {
            return !(/^[ ]+$/.test(target) || !target)
        },

        onSubmit() {
            if (!this.checkString(this.businessName)) {
                return this.$Message.error("业务功能名称不能为空")
            }

            const data = {
                appName: this.businessName,
                creatorId: parent.vue.loginInfo.userinfo.id,
                creatorName: parent.vue.loginInfo.userinfo.realName,
                description: this.businessDescription,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.$core.getUrlParam("orgCode"),
                orgLevel: 4,
            }

            if (this.businessId) {
                data.appId = this.businessId
            }

            this.$post(`/gateway/api/fastDev/AppForm/${this.businessId ? "updateUserInfoAppForm" : "addUserInfoAppForm"}` + Utils.jsonToParams(data), this.getSelectedIds(), {
                "Content-Type": "application/json",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("提交成功")
                    // 返回
                    Route.back()
                } else this.$Message.error(res.desc)
            })
        },

        getSelectedIds() {
            const ids = []

            for (let i = 0, s = this.selected; i < s.length; i++) {
                let v = s[i]
                ids.push({
                    fieldId: v.fieldId,
                    required: v.required,
                })
            }

            return ids
        },

        onPreview() {
            if (this.selected.length <= 0) {
                return this.$Message.info("预览组件不能为空")
            }

            this.$refs.preview_table.onDisplay(this.selected)
        },
    },
}
</script>

<style lang="less">
.residentdatabase-fixed-business {
    height: 100%;
    min-width: 800px;

    > .head-controller-box {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #e3e3e3;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        flex-shrink: 0;
        justify-content: space-between;

        > .back-btn {
            position: relative;
            height: 100%;
            cursor: pointer;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                position: absolute;
                top: 20px;
                right: 0;
                bottom: 20px;
                content: "";
                width: 1px;
                background: #eee;
            }

            .icon {
                font-size: 26px;
                font-weight: bold;
            }
        }

        .title-box {
            font-size: 20px;
            font-weight: bold;
            flex-grow: 1;
            margin-left: 30px;
        }

        .cancel-button {
            cursor: pointer;
            margin: 0 30px;
            height: 40px;
            padding: 0 20px;
            background: #fff;
            border: 1px solid #e3e3e3;
            transition: all 0.3s;
            flex-shrink: 0;
            border-radius: 5px;
            display: flex;
            align-items: center;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            .icon {
                font-size: 20px;
                color: #888;
            }

            .submit {
                margin-left: 10px;
                color: #888;
                font-size: 16px;
            }
        }
    }

    > .content-box {
        width: 100%;
        height: ~"calc(100% - 71px)";
        display: flex;

        .options-box {
            width: 100%;
            height: 100%;
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;

            > .item-box {
                padding: 0 20px 40px 30px;

                &:first-child {
                    padding-top: 40px;
                }

                > .item-base {
                    > .title-box {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .serial-number {
                            width: 40px;
                            height: 40px;
                            font-size: 20px;
                            color: #36414e;
                            border: 1px solid #e3e3e3;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .name {
                            font-size: 25px;
                            margin-left: 20px;
                            color: #36414e;
                        }
                    }

                    .step-content-box {
                        margin: 20px 0 0 52px;
                        display: flex;
                        flex-wrap: wrap;

                        .checkbox {
                            margin: 8px;
                        }

                        .form-box {
                            display: flex;
                            flex-wrap: wrap;

                            .form-item {
                                width: 300px;
                                margin: 10px 40px 10px 0;

                                .name-box {
                                    font-size: 15px;
                                    margin-bottom: 5px;
                                    color: #888;
                                }
                            }
                        }
                    }
                }
            }
        }

        .selected-box {
            position: relative;
            width: 350px;
            margin: 20px 20px 20px 30px;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            background: #fff;
            flex-shrink: 0;
            transition: all 0.3s;
            overflow: hidden;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            .title-box {
                position: sticky;
                top: 0;
                line-height: 45px;
                border-bottom: 1px solid #f3f3f3;
                font-size: 16px;
                color: #333;
                text-align: center;
                background: #fff;
            }

            .selected-base {
                position: absolute;
                top: 45px;
                left: 0;
                bottom: 40px;
                right: 0;
                overflow-y: auto;
                overflow-x: hidden;

                .item-box {
                    margin: 0 10px;
                    padding: 12px 0 10px 8px;
                    border-bottom: 1px solid #f3f3f3;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        border-bottom: 0;
                    }

                    .name {
                        flex-grow: 1;
                    }

                    .delete-box,
                    .check-box {
                        flex-shrink: 0;
                    }

                    .delete-box {
                        margin-right: 8px;
                    }

                    .liefeng-icon {
                        font-size: 16px;
                        line-height: 1;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }

                .empty {
                    position: absolute;
                    top: 45px;
                    left: 0;
                    bottom: 40px;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #888;
                }
            }

            .preview {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #2faaf7;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
</style>
